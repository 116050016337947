import { Action, action } from 'easy-peasy'

import { APP_VERSION, RELATED_PLATFORM_SITE } from 'config/app-version'

import { User } from 'types'

export interface UserModel extends User {
  // Actions
  resetStore: Action<UserModel>
  setUser: Action<UserModel, User>
  updateUser: Action<UserModel, Partial<User>>
}

const initialState: User = {
  appVersion: APP_VERSION || '',
  cashOutCode: undefined,
  isActive: true,
  key: '',
  name: undefined,
  relatedPlatformSite: RELATED_PLATFORM_SITE,
  shopKeys: [],
  userId: '',
  wallets: undefined,
}

const userModel: UserModel = {
  ...initialState,
  resetStore: action((_state: User) => ({ ...initialState })),
  setUser: action(
    (state: User, payload: User): User => ({ ...state, ...payload }),
  ),
  updateUser: action(
    (state: User, payload: Partial<User>): User => ({
      ...state,
      ...payload,
    }),
  ),
}

export { userModel }
