import { Action, action } from 'easy-peasy'

import { GameType } from 'types'

interface GameTypesState {
  // State
  gameTypes: GameType[]
}

export interface GameTypesModel extends GameTypesState {
  // Actions
  resetStore: Action<GameTypesModel>
  setGameTypes: Action<GameTypesModel, GameType[]>
}

const initialState: GameTypesState = {
  gameTypes: [],
}

const gameTypesModel: GameTypesModel = {
  ...initialState,
  resetStore: action((_state: GameTypesState): any => ({
    ...initialState,
  })),
  setGameTypes: action(
    (state: GameTypesState, gameTypes: GameType[]): GameTypesState => ({
      ...state,
      gameTypes,
    }),
  ),
}

export { gameTypesModel }
